
import { DataTable } from "@/models/utils/datatable.interface";
import { Action } from "@/models/utils/table-action.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { Component, Vue } from "vue-property-decorator";
import SupplierForm from "./SupplierForm.vue";
import { suppliers } from "@/services/api/suppliers.service";
import { Supplier } from "@/models/entities/supplier.interface";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({ components: { SupplierForm } })
export default class Suppliers extends Vue {
  private dialogDelete = false;
  private onDeleteMessage = "";
  private supplierToDelete: number = null;
  private supplierDialog = {
    show: false,
    title: "",
    icon: "",
    supplier: {} as Supplier,
  };
  private valid = false;
  private search = "";

  private headers: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Nome",
      align: "start",
      filterable: true,
      value: "companyName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Indirizzo",
      align: "start",
      filterable: true,
      value: "fullAddress",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "P.IVA",
      align: "start",
      filterable: true,
      value: "vatNumber",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Email",
      align: "start",
      filterable: true,
      value: "email",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Telefono",
      align: "start",
      filterable: true,
      value: "phone",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];

  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.EDIT, name: "edit" },
      { id: Action.DELETE, name: "delete" },
    ],
  };

  async created(): Promise<void> {
    await this.getTableItems();
  }

  async getTableItems(): Promise<void> {
    overlayModule.showOverlay();
    const r = await suppliers.getAllSuppliers();
    this.items.loading = false;
    this.$set(this.items, "values", r.data);
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private async showSupplierDialog(item: Supplier): Promise<void> {
    this.$set(this.supplierDialog, "show", true);
    this.$set(this.supplierDialog, "icon", "mdi-truck");
    if (item) {
      this.$set(this.supplierDialog, "title", "Modifica fornitore");
      overlayModule.showOverlay();
      const r = await suppliers.getSupplierByID(item.id);
      this.$set(this.supplierDialog, "supplier", r.data);
    } else {
      this.$set(this.supplierDialog, "title", "Nuovo fornitore");
      this.$set(this.supplierDialog, "supplier", {} as Supplier);
    }
  }

  private closeSupplierDialog(): void {
    this.$set(this.supplierDialog, "show", false);
  }

  private async saveSupplier(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      if (this.supplierDialog.supplier.id) {
        overlayModule.showOverlay();
        await suppliers.updateSupplier(
          this.supplierDialog.supplier.id,
          this.supplierDialog.supplier
        );
      } else {
        overlayModule.showOverlay();
        await suppliers.saveSupplier(this.supplierDialog.supplier);
      }
      this.getTableItems();
      this.$set(this.supplierDialog, "show", false);
      snackbarModule.showSnackbar({
        message: "Salvataggio avvenuto con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private async onDelete(item: Supplier): Promise<void> {
    this.supplierToDelete = item.id;
    this.onDeleteMessage =
      "Sei sicuro di volere eliminare il fornitore " +
      item.companyName.toUpperCase() +
      " ?";
    this.dialogDelete = true;
  }

  private async deleteSupplier(): Promise<void> {
    overlayModule.showOverlay();
    await suppliers.deleteSupplier(this.supplierToDelete);
    this.getTableItems();
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }
}
