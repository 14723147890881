var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('h2',[_vm._v("Fornitori")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"solo":"","flat":"","rounded":"","hide-details":"","clearable":"","label":"Cerca","append-icon":"mdi-magnify"},on:{"input":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"py-5",attrs:{"color":"primary darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.showSupplierDialog(null)}}},[_vm._v(" Nuovo fornitore ")])],1)],1),_c('ll-data-table',{staticClass:"mt-10",attrs:{"items":_vm.items},on:{"edit":function($event){return _vm.showSupplierDialog($event)},"delete":_vm.onDelete}}),(_vm.supplierDialog.show)?_c('ll-dialog',{attrs:{"show":_vm.supplierDialog.show,"title":_vm.supplierDialog.title,"icon":_vm.supplierDialog.icon,"valid":_vm.valid},on:{"close":_vm.closeSupplierDialog,"save":_vm.saveSupplier},scopedSlots:_vm._u([{key:"dialogBody",fn:function(){return [_c('v-form',{ref:"form",staticClass:"py-10 px-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('supplier-form',{attrs:{"supplier":_vm.supplierDialog.supplier},on:{"validate":function($event){_vm.$nextTick(() => {
              _vm.$refs.form.validate();
            })}}})],1)]},proxy:true}],null,false,2058822721)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndDown
        ? '90%'
        : _vm.$vuetify.breakpoint.lg
        ? '55%'
        : '50%'},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 py-6",staticStyle:{"word-break":"normal !important"}},[_vm._v(" "+_vm._s(_vm.onDeleteMessage)+" ")]),_c('v-card-text',{staticClass:"text-h6"},[_vm._v(" Attenzione: verranno eliminati tutti i prodotti e i listini associati al fornitore. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogDelete = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"color":"error darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSupplier.apply(null, arguments)}}},[_vm._v(" Elimina ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }