
import { Supplier } from "@/models/entities/supplier.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  required,
  email,
  vatNumber,
  postalCode,
  province,
} from "@/helpers/rules";

@Component
export default class SupplierForm extends Vue {
  /** Sets the form value */
  @Prop({ required: true }) private supplier: Supplier;

  private required = required;
  private email = email;
  private vatNumber = vatNumber;
  private postalCode = postalCode;
  private province = province;

  mounted(): void {
    this.$emit("validate");
  }
}
